var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"id":"data-bancos-isola","headers":_vm.headerBanco,"items":_vm.datos,"search":_vm.search,"loading":_vm.loadingData,"footer-props":{itemsPerPageText: 'Mostrar'}},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text font-weight-bold",class:{'display': !_vm.$vuetify.breakpoint.mobile, 'subtitle-1': _vm.$vuetify.breakpoint.mobile },domProps:{"textContent":_vm._s(item.nombre)}})]}},{key:"item.moneda",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase",class:{'display': !_vm.$vuetify.breakpoint.mobile, 'subtitle-1': _vm.$vuetify.breakpoint.mobile },domProps:{"textContent":_vm._s(item.moneda)}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":item.status ? 'success-alert' : 'disabled-alert',"small":"","label":"","pill":"","dark":""}},[_vm._v(" "+_vm._s(item.status ? 'Activo' : 'Inactivo')+" ")])],1)]}},{key:"item.iconos",fn:function(ref){
var item = ref.item;
return [(_vm.$hasPermission('administracion.bancos.editar'))?_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"plain":"","icon":""},on:{"click":function($event){return _vm.actionUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"blue-grey","left":_vm.$vuetify.breakpoint.xsOnly},domProps:{"textContent":_vm._s('mdi-file-document-edit-outline')}}),(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_vm._v("Editar")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Editar Banco")])])],1):_vm._e()]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center pa-4"},[_c('span',{staticClass:"blue-grey--text font-italic"},[_vm._v("No se encontraron Bancos Registrados")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }