<template>
  <div>
    <v-data-table
      id="data-bancos-isola"
      :headers="headerBanco"
      :items="datos"
      :search="search"
      :loading="loadingData"
      :footer-props="{itemsPerPageText: 'Mostrar'}"
    >
      <template v-slot:item.nombre="{item}">
        <span 
          class="primary--text font-weight-bold" 
          :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }" 
          v-text="item.nombre" /> 
      </template>
      <template v-slot:item.moneda="{item}">
        <span 
          class="text-uppercase" 
          :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }" 
          v-text="item.moneda" /> 
      </template>
      <template v-slot:item.status="{ item }">
          <div class="text-center">
            <v-chip
              class="ma-2 font-weight-bold"
              :color="item.status ? 'success-alert' : 'disabled-alert' "
              small
              label
              pill
              dark
            >
              {{ item.status ? 'Activo' : 'Inactivo' }}
            </v-chip>          
          </div>
        </template>
      <template v-slot:item.iconos="{item}">
        <div 
          v-if="$hasPermission('administracion.bancos.editar')"
          class="d-flex flex-row justify-end"
        >
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  plain 
                  icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="actionUser(item)"
                >
                  <v-icon                    
                    size="20" 
                    color="blue-grey"  
                    :left="$vuetify.breakpoint.xsOnly"                   
                    v-text="'mdi-file-document-edit-outline'"
                  />
                  <span v-if="$vuetify.breakpoint.xsOnly">Editar</span>
                </v-btn>
              </template>
              <span>Editar Banco</span>
          </v-tooltip>           
        </div>
      </template>
      <template #loading>
        <v-skeleton-loader
          type="table-tbody"
        ></v-skeleton-loader>
      </template>
      <template v-slot:no-data>
        <div class="text-center pa-4">
          <span class="blue-grey--text font-italic">No se encontraron Bancos Registrados</span>
        </div>
      </template>
    </v-data-table>    
  </div>
</template>
<script>
export default {
  name: 'BancosData',
  props:{
    datos: {
      type: Array,
      default: () => ([])
    },
    search: String,
    loadingData: Boolean,
  },
  data: () => ({    
    headerBanco:[
      { text: '#', value: 'cod_banco', class: 'header-data', width: '50' },
      // { text: 'Cod Cliente', value: 'co_cli', class: 'header-data', width: '20', align: ' d-none'},
      // { text: 'Cod Vendedor', value: 'co_ven', class: 'header-data', width: '20', align: ' d-none'},
      {
        text: 'Nombre',
        align: 'start',
        value: 'nombre',
        class: 'header-data'
      },
      {
        text: 'Titular',
        align: 'start',
        value: 'titular',
        class: 'header-data'
      },
      {
        text: 'Moneda',
        align: 'start',
        value: 'moneda',
        class: 'header-data',
        width: '120'
      },
      { text: 'Estatus', value: 'status', class: 'header-data px-0 d-flex justify-center align-center' },
      { text: '', value: 'iconos', class: 'header-data', width: '30'},
    ],
  }),
  methods:{
    actionUser(item) {
      this.$emit('update', item);
    }
  },
}
</script>